import React from "react";
import Head_estimate from "../../../components/modules/services/estimate/HeadEstimate";
import OurServicesEstimate from "../../../components/modules/services/estimate/OurServicesEstimate";
import OurRespEstimate from "components/modules/services/estimate/OurRespEstimate";
import WhyUs from "components/modules/services/estimate/WhyUs";
import BusinessEstimate from "components/modules/services/estimate/BusinessEstimate";
import TestimonialsEstimate from "components/modules/services/estimate/TestimonialsEstimate";
import Partners from "components/modules/showcase/Partners";
import LastNews from "components/modules/showcase/LastNews";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const Estimate = () => {
  return (
    <>
      <Head_estimate />
      <Breadcrumbs />
      <OurServicesEstimate />
      <OurRespEstimate />
      <WhyUs />
      <BusinessEstimate />
      <Partners />
      {/*<TestimonialsEstimate />*/}
      {/*<LastNews />*/}
    </>
  );
};

export default Estimate;
