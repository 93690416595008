import ImportancySoftware from "components/modules/services/billing-software/edi/ImportancySoftware";
import Creation from "components/modules/services/billing-software/edi/Creation";
import Head from "components/modules/services/billing-software/edi/Head";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import Solution from "components/modules/services/billing-software/edi/Solution";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const EDISolution = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <ImportancySoftware />
      <Solution />
      <Creation />
      {/*<ClientTalk />*/}
      <Partners />
    </>
  );
};

export default EDISolution;
