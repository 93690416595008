import React from "react";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";


const ClientTalkforUs = () => {
  const testimonials = [
    {
      quote:
        "La qualité du service est excellente, nous avons été très satisfaits de la prestation. Les résultats ont dépassé nos attentes.",
      rating: 3,
      avatar: logo_ipercom,
      name: "",
    },
    {
      quote:
        "Nous avons été impressionnés par la rapidité de réponse et la diligence de l'équipe. Ils ont su répondre à nos besoins de manière efficace.",
      rating: 5,
      avatar: logo_ipercom,
      name: "",
    },
    {
      quote:
        "Le professionnalisme et l'expertise de l'équipe ont été remarquables. Ils ont su résoudre nos problèmes de manière créative et innovante.",
      rating: 4,
      avatar: logo_ipercom,
      name: "",
    },
    {
      quote:
        "Nous avons été ravis de la qualité du produit ainsi que du service après-vente. Nous recommandons vivement cette entreprise.",
      rating: 3,
      avatar: logo_ipercom,
      name: "",
    },
    {
      quote:
        "L'équipe a su comprendre nos besoins et nous fournir des solutions sur mesure. Nous sommes très satisfaits de la collaboration.",
      rating: 4,
      avatar: logo_ipercom,
      name: "",
    }
  ];

  return (
    <section className="container py-5">
      <div className="row pt-xl-1 pb-xl-3">
        <div className="col-lg-3 col-md-4">
          <h2
            className="text-center text-md-start mx-auto mx-md-0 pt-md-2"
            style={{ maxWidth: 300 }}
          >
            Avis Google :
            {/*Ce que nos <br className="d-none d-md-inline" />*/}
            {/*clients disent <br className="d-none d-md-inline" />*/}
            {/*de nous :*/}
          </h2>
          <div className="d-flex justify-content-center justify-content-md-start pb-4 mb-2 pt-2 pt-md-4 mt-md-5">
            <button
              id={"btn-prev"}
              type="button"
              className="btn-prev btn-icon btn-sm me-2"
              aria-label="Previous"
            >
              <i className="bx bx-chevron-left"></i>
            </button>
            <button
              id={"btn-next"}
              type="button"
              className="btn-next btn-icon btn-sm ms-2"
              aria-label="Next"
            >
              <i className="bx bx-chevron-right"></i>
            </button>
          </div>
        </div>

        <div className="col-lg-9 col-md-8">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            spaceBetween={8}
            loop={true}
            navigation={{
              prevEl: ".btn-prev",
              nextEl: ".btn-next"
            }}
            breakpoints={{
              500: {
                slidesPerView: 2
              },
              1000: {
                slidesPerView: 2
              },
              1200: {
                slidesPerView: 3
              }
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="h-auto pt-4" style={{ width: 300 }}>
                  <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                    <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                      <span
                        className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                        {/*<i className="bx bxs-quote-left"></i>*/}
                       <i className="bx bxl-google fs-xl "></i>
                      </span>
                      <blockquote className="card-body pb-3 mb-0">
                        <p className="mb-0">{testimonial.quote}</p>
                      </blockquote>
                      <div className="card-footer border-0 text-nowrap pt-0">
                        {[...Array(testimonial.rating)].map((_, i) => (
                          <i key={i} className="bx bxs-star text-warning"></i>
                        ))}
                        {[...Array(5 - testimonial.rating)].map((_, i) => (
                          <i
                            key={i}
                            className="bx bx-star text-muted opacity-75"
                          ></i>
                        ))}
                      </div>
                    </div>
                    {/*<figcaption className="d-flex align-items-center ps-4 pt-4">*/}
                    {/*  <img*/}
                    {/*    src={testimonial.avatar}*/}
                    {/*    width="48"*/}
                    {/*    className="rounded-circle"*/}
                    {/*    alt={testimonial.name}*/}
                    {/*  />*/}
                    {/*  <div className="ps-3">*/}
                    {/*    <h3 className="fs-sm fw-semibold mb-0">*/}
                    {/*      {testimonial.name}*/}
                    {/*    </h3>*/}
                    {/*  </div>*/}
                    {/*</figcaption>*/}
                  </figure>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ClientTalkforUs;
