import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import bvannu from "assets/img/ipercom/services/ERP/logo-bvannu.webp";
import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";


const Testimonials = () => {
  const testimonials = [
    {
      text: "Je suis impressionnée par la qualité et la polyvalence d'ipercom. Leur large gamme de fonctionnalités répond à tous mes besoins professionnels. Je les recommande vivement à toute personne cherchant à optimiser son travail.",
      name: "",
      avatar: null
    },
    {
      text: "Depuis le temps que je cherchais à mutualiser mes services ERP et téléphonie. C'est maintenant chose faite. Mon entreprise a enfin trouvé son autonomie grâce à ipercom. De très bons conseils, pas de bla bla, de l'efficacité. Du travail de pro, réalisé par de vrais spécialistes. Mon entreprise utilise maintenant des services modernes, cela nous permet d'optimiser nos taches, dans un confort absolu. je conseille vivement.",
      name: "bvannu",
      avatar: bvannu
    },
  ];

  return (
    <section className="bg-secondary py-5">
      <div className="container py-2 py-md-3 py-lg-4 py-xl-5">
        <div className="row row-cols-1 row-cols-md-2 gx-3 gx-lg-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <div className="card border-0 bg-dark h-100 p-xl-4">
              <div className="card-body">
                <h2 className="display-1 text-light">Avis Client</h2>
                <p className="lead fw-medium text-light">
                  <span className="opacity-70">
                    Notre outil de gestion des tâches révolutionne votre
                    productivité. Découvrez une approche transparente et
                    organiser vos activités quotidiennes.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card border-0 shadow-sm p-4 p-xxl-5">
              <div className="d-flex justify-content-between pb-4 mb-2">
                <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                  {/*<i className="bx bxs-quote-left"></i>*/}
                 <i className="bx bxl-google fs-xl " />
                </span>
                <div className="d-flex">
                <button
                    className="btn btn-prev btn-icon btn-sm me-2"
                    aria-label="Previous"
                  >
                    <i className="bx bx-chevron-left"></i>
                  </button>
                  <button
                    className="btn btn-next btn-icon btn-sm ms-2"
                    aria-label="Next"
                  >
                    <i className="bx bx-chevron-right"></i>
                  </button>
                </div>
              </div>

              <Swiper
                spaceBetween={24}
                loop={true}
                pagination={{
                  clickable: true
                }}
                navigation={{
                  prevEl: ".btn-prev",
                  nextEl: ".btn-next"
                }}
                modules={[Pagination, Navigation]}
                className="swiper mx-0 mb-md-n2 mb-xxl-n3"
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index} className="h-auto">
                    <figure className="card h-100 position-relative border-0 bg-transparent">
                      <blockquote className="card-body p-0 mb-0">
                        <p className="fs-lg mb-0">{testimonial.text}</p>
                      </blockquote>
                      <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                        {/*<img*/}
                        {/*  src={testimonial.avatar}*/}
                        {/*  width="60"*/}
                        {/*  className="rounded-circle"*/}
                        {/*  alt={testimonial.name}*/}
                        {/*/>*/}
                        <div className="ps-3">
                          <p className="fw-semibold lh-base mb-0">
                            {testimonial.name}
                          </p>
                          
                        </div>
                      </figcaption>
                    </figure>
                  </SwiperSlide>
                ))}
                <div className="swiper-pagination position-relative mt-5"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
