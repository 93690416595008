import ipos from 'assets/img/ipercom/services/ERP/iposClientTalk.svg';
import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import React from "react";

const ClientTalk = () => {
  const testimonials = [
    {
      quote:
        "ipercom a révolutionné notre façon de gérer l'entreprise. Ses fonctionnalités avancées et son support client exceptionnel en font un choix incontournable!",
      name: '',
      job: 'Directeur du marketing',
      avatar: logo_ipercom,
      companyAvatar: ipos
    },
    {
      quote:
        "Un outil puissant pour optimiser nos opérations. Nous avons remarqué une amélioration significative de notre efficacité globale depuis que nous avons commencé à utiliser ipercom.",
      name: '',
      job: 'Directrice des opérations',
      avatar: logo_ipercom,
      companyAvatar: ipos
    }
  ];

  return (
    <section className="container mb-2 pb-lg-2 pb-md-2 pb-2">
      <div className="text-start">
        <h2 className="mb-4">Ce que disent nos clients</h2>
        <div className="position-relative">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            loop={true}
            pagination={{
              clickable: true,
              el: ".swiper-pagination-testimonials"
            }}
            navigation={{
              prevEl: "#prev-testimonials",
              nextEl: "#next-testimonials"
            }}
            className="mx-n2"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img
                      src={testimonial.avatar}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card border-0 shadow-sm p-4 p-xxl-5">
                      <div className="d-flex justify-content-between pb-4 mb-2">
                        <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                          {/*<i className="bx bxs-quote-left" />*/}
                         <i className="bx bxl-google fs-xl " />
                        </span>
                      </div>
                      <div className="mx-0 mb-md-n2 mb-xxl-n3">
                        <div className="h-auto">
                          <figure className="card h-100 position-relative border-0 bg-transparent">
                            <blockquote className="card-body p-0 mb-0">
                              <p className="fs-lg mb-0">{testimonial.quote}</p>
                            </blockquote>
                            <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                              <div
                                className="me-3 pe-3"
                                // style={{ borderRight: "2px solid #E2E5F1" }}
                              >
                                <h3 className="h6 fw-semibold lh-base mb-0">
                                  {testimonial.name}
                                </h3>
                                {/* <span className="fs-sm text-muted">
                                  {testimonial.job}
                                </span> */}
                              </div>
                              {/* <img
                                src={testimonial.companyAvatar}
                                width={100}
                                alt={testimonial.name}
                              /> */}
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination-testimonials position-relative bottom-0 mt-4 mb-lg-2"></div>
          <button
            type="button"
            id="prev-testimonials"
            className="btn btn-prev btn-icon btn-sm me-2 "
            aria-label="Previous"
          >
            <i className="bx bx-chevron-left"></i>
          </button>
          <button
            type="button"
            id="next-testimonials"
            className="btn btn-next btn-icon btn-sm "
            aria-label="Next"
          >
            <i className="bx bx-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ClientTalk;
