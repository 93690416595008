import React from "react";
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import alphalink from "assets/img/ipercom/landing/partners/alphalink.webp";
import amazon_ws from "assets/img/ipercom/landing/partners/amazon_ws.webp";
import bouygues from "assets/img/ipercom/landing/partners/bouygues.webp";
import ovh from "assets/img/ipercom/landing/partners/ovh.svg";
import sfr from "assets/img/ipercom/landing/partners/sfr.webp";
import ms_azure from "assets/img/ipercom/landing/partners/ms_azure.webp";
import orange from "assets/img/ipercom/landing/partners/orange.svg";
import unyc from "assets/img/ipercom/landing/partners/unyc.webp";
import cisco from "assets/img/ipercom/landing/partners/cisco.webp";
import gigaset from "assets/img/ipercom/landing/partners/gigaset.webp";
import fanvil from "assets/img/ipercom/landing/partners/fanvil.webp";
import snom from "assets/img/ipercom/landing/partners/snom.webp";
import yealink from "assets/img/ipercom/landing/partners/yealink.webp";
import sequenceinformatique from "assets/img/ipercom/landing/partners/sequence-informatique.webp";
import hexapage from "assets/img/ipercom/landing/partners/hexapage.webp";

const partnerLogos = [
  { src: unyc, width: 135, height: 60 },
  { src: ms_azure, width: 135, height: 60 },
  { src: alphalink, width: 135, height: 60 },
  { src: orange, width: 135, height: 60 },
  { src: sfr, width: 135, height: 60 },
  { src: bouygues, width: 135, height: 60 },
  { src: amazon_ws, width: 135, height: 60 },
  { src: ovh, width: 135, height: 60 },
  { src: cisco, width: 135, height: 60 },
  { src: fanvil, width: 135, height: 60 },
  { src: gigaset, width: 135, height: 60 },
  { src: snom, width: 135, height: 60 },
  { src: yealink, width: 135, height: 60 },
  { src: sequenceinformatique, width: 135, height: 60 },
  { src: hexapage, width: 135, height: 60 }
];

SwiperCore.use([Autoplay]);

const Partners = () => {
  return (
    <section className="container my-3">
      <div className="row justify-content-center pt-1 mb-md-3 mb-lg-4">
        <div className="col-lg-8 col-md-9 text-center">
          <h2>Partenaires ipercom</h2>
        </div>
      </div>
      <Swiper
        spaceBetween={20}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={true}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
      >
        {partnerLogos.map((logo, index) => (
          <SwiperSlide key={index} className="w-auto pt-1">
            <div
              className="card card-body card-hover d-flex justify-content-center align-items-center mx-2"
              style={{ width: "200px", height: "100px" }}
            >
              <img
                src={logo.src}
                className="d-block mx-auto img-fluid"
                style={{ maxHeight: "100%", objectFit: "contain" }}
                alt={`Partner ${index}`}
                width={logo.width}
                height={logo.height}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Partners;
