import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon, UilCube } from "@iconscout/react-unicons";

export interface Route {
  name: string;
  description?: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  name: string;
  description?: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  path?: string;
  megaMenu?: boolean;
  active?: boolean;
}

// Changed from 'let' to 'const'
export const routes: RouteItems[] = [
  {
    name: 'Services',
    description:
      "Profitez de notre expertise et de nos services complets pour une satisfaction garantie.",
    icon: UilCube,
    pages: [
      {
        name: 'ERP',
        path: '/services/erp',
        pathName: 'erp',
        icon: 'grid',
        active: true,
        pages: [

          {
            name: `Notre CRM `,
            path: '/services/erp/notre-crm',
            pathName: "erp"
          },
          {
            name: `Solution Facturation`,
            path: '/services/erp/solution-facturation',
            pathName: "erp"
          },
          {
            name: `Création de Devis  `,
            path: '/services/erp/creation-de-devis',
            pathName: "erp"
          },
          {
            name: `Gestion de stock `,
            path: '/services/erp/gestion-de-stock',
            pathName: "erp"
          },
          {
            name: `ERP pour les petites entreprises`,
            path: '/services/erp/erp-pour-petite-entreprise',
            pathName: "erp"
          },
          {
            name: `ERP pour les entreprises de taille moyenne`,
            path: '/services/erp/erp-pour-moyenne-entreprise',
            pathName: "erp"
          },
          {
            name: `ERP pour les grandes entreprises`,
            path: '/services/erp/erp-pour-grande-entreprise',
            pathName: "erp"
          }
        ]
      },
      {
        name: 'Solutions de téléphonie',
        path: '/services/solution-telephonie',
        pathName: 'solution-telephonie',
        icon: 'grid',
        active: true,
        pages: [

          {
            name: `Logiciel de téléphonie iper-X`,
            path: '/services/solution-telephonie/logiciel-de-telephonie-iper-x',
            pathName: "solution-telephonie"
          }
        ]
      },
      {
        name: 'Services proposés',
        path: '/services/services-proposes',
        pathName: 'services-proposes',
        icon: 'grid',
        active: true,
        pages: [
          {
            name: `Formations`,
            path: '/services/services-proposes/formation',
            pathName: "services-proposes"
          },
          {
            name: `Logiciel et application de caisse`,
            path: '/services/services-proposes/logiciel-et-application-de-caisse',
            pathName: "services-proposes"
          },
          {
            name: `Développement d'applications web`,
            path: '/services/services-proposes/developpement-application-web',
            pathName: "services-proposes"
          },
          {
            name: `Développement d'applications mobiles`,
            path: '/services/services-proposes/developpement-application-mobile',
            pathName: "services-proposes"
          },
          {
            name: `Conception de logiciels sur mesure`,
            path: '/services/services-proposes/conception-logiciel-sur-mesure',
            pathName: "services-proposes"
          },
          {
            name: `Intégration de solutions logicielles`,
            path: '/services/services-proposes/integration-de-solutions-logicielles',
            pathName: "services-proposes"
          },
          {
            name: "Solution iper-cloud",
            path: "/services/services-proposes/solution-iper-cloud",
            pathName: "services-proposes"
            
          },
          {
            name: `Maintenance et support technique`,
            path: '/services/services-proposes/maintenance-et-support-technique',
            pathName: "services-proposes"
          }
        ]
      },
      {
        name: 'Logiciel de facturation par métier',
        path: '/services/logiciel-de-facturation-par-metier',
        pathName: 'logiciel-de-facturation-par-metier',
        icon: 'grid',
        active: true,
        pages: [
          {
            name: `Logiciel de facturation bureautique`,
            path: '/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-bureautique',
            pathName: "logiciel-de-facturation-par-metier"
          },
          {
            name: `Logiciel de facturation informatique`,
            path: '/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-informatique',
            pathName: "logiciel-de-facturation-par-metier"
          },
          {
            name: `Logiciel de facturation télécom`,
            path: '/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-telecom',
            pathName: "logiciel-de-facturation-par-metier"
          },
          {
            name: `Solution EDI`,
            path: '/services/logiciel-de-facturation-par-metier/solution-edi',
            pathName: "logiciel-de-facturation-par-metier"
          }
          // {
          //   name: `Module sécurité vidéosurveillance intrusion et contrôle d’accès (en cours)`,
          //   path: '#',
          //   pathName: 'logiciel-de-facturation-par-metier',
          // },
        ]
      }
    ]
  },
  {
    name: 'Ressources',
    description:
      "Des ressources fiables pour vous accompagner dans vos projets.",
    icon: UilCube,
    pages: [
      {
        name: 'Documentation',
        path: "/ressources/documentation",
        pathName: 'documentation',
        icon: 'grid',
        active: true,
        pages: [
          {
            name: `Livre blanc `,
            path: "/ressources/documentation/livres-blancs",
            pathName: "documentation"
          },
          {
            name: `Guides pratiques `,
            path: "/ressources/documentation/guides-pratiques",
            pathName: "documentation"
          }
        ]
      },
      {
        name: 'Blog',
        path: "/ressources/blog",
        pathName: 'blog',
        icon: "message-detail",
        active: true,
        pages: [
          {
            name: `Articles sur les tendances du développement logiciel `,
            path: "/ressources/blog/articles-tendances-developpement-logiciel",
            pathName: "services"
          },
          {
            name: `Conseils pratiques pour les entreprises `,
            path: "/ressources/blog/conseils-pratiques-pour-les-entreprises",
            pathName: "services"
          },
          {
            name: `Nouveautés de l'agence et du secteur`,
            path: "/ressources/blog/nouveautes-agence-et-secteur",
            pathName: "services"
          }
        ]
      },
      {
        name: 'Portfolio',
        path: "/ressources/portfolio",
        pathName: 'portfolio',
        icon: "folder-open",
        active: true,
        pages: [
          {
            name: `Projets réalisés `,
            path: "/ressources/portfolio/projets-realises",
            pathName: "portfolio"
          },
          {
            name: `Avis clients `,
            path: "/ressources/portfolio/avis-clients",
            pathName: "portfolio"
          }
        ]
      },
      {
        name: "ERP",
        path: "/services/erp",
        pathName: "erp",
        icon: "grid",
        active: true,
        pages: [
          {
            name: `Qu'est-ce qu'un ERP ?`,
            path: "/services/erp/qu-est-ce-qu-un-erp",
            pathName: "erp"
          },
          {
            name: `Guide pour un ERP`,
            path: "/services/erp/guide-pour-un-erp",
            pathName: "erp"
          },
          {
            name: `Qu'est-ce qu'un CRM ?`,
            path: "/services/erp/qu-est-ce-qu-un-crm",
            pathName: "erp"
          },
          {
            name: `Qu'est-ce qu'un Standard téléphonique IPBX ?`,
            path: "/services/solution-telephonie/qu-est-ce-qu-un-standard-telephonique-ipbx",
            pathName: "solution-telephonie-ipbx"
          }
        ]
      }
    ]
  },
  {
    name: 'À Propos',
    description: "Découvrez qui nous sommes et ce que nous faisons.",
    icon: UilCube,
    pages: [
      {
        name: `À Propos`,
        path: "/a-propos",
        icon: "folder-open",
        pages: [
          {
            name: `Historique de l'agence `,
            path: "/a-propos/historique-agence",
            pathName: "a-propos"
          },
          {
            name: `Engagements et valeurs `,
            path: "/a-propos/engagements-et-valeurs",
            pathName: "a-propos"
          },
          {
            name: `Équipe et compétences `,
            path: "/a-propos/equipe-et-competences",
            pathName: "a-propos"
          },
          {
            name: `Carrières`,
            path: "/a-propos/carriere",
            pathName: "a-propos"
          },
          {
            name: `Devenir revendeur`,
            path: "/a-propos/devenir-revendeur",
            pathName: "a-propos"
          },
          {
            name: `Trouver un revendeur`,
            path: "/a-propos/trouver-un-revendeur",
            pathName: "a-propos"
          },
          {
            name: `FAQ`,
            path: "/a-propos/faq",
            pathName: "a-propos"
          }
        ]
      }
    ]
  },
  {
    name: 'Contact',
    icon: UilCube,
    path: "/contact",
    pages: []
  },
  {
    name: 'Abonnements',
    icon: UilCube,
    path: "/abonnements",
    pages: []
  }
];
