import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";

gsap.registerPlugin(ScrollTrigger);

const ERPhierarchy = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container text-center py-5">
      <div
        className="position-relative rounded-3 overflow-hidden mb-3"
        style={{ maxWidth: '650px', margin: '0 auto' }}
      >
        <iframe
          width="750px"
          height="366"
          src="https://www.youtube.com/embed/w6VF48lpCyc?si=2kjCkhSjkhtgE-_k&autoplay=1&mute=1&loop=1&playlist=w6VF48lpCyc"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </section>

    // <video class="elementor-video" src="https://consiliumxp.com/wp-content/uploads/2024/09/Showreel-consiliumXP-ref1.webm" autoplay="" loop="" muted="muted" playsinline="" controlslist="nodownload"></video>

    // <section className="container pt-5 mt-3 mt-sm-4 mt-xl-5">
    //   <h2 className="h1 text-center pb-3 pb-lg-4">
    //     La hiérarchie de notre ERP
    //   </h2>
    //
    //   <div className="d-flex justify-content-center align-items-center mx-xl-n2 mt-n4 px-sm-3">
    //     <img
    //       src={hierarchy2}
    //       alt="Iper-x"
    //       width="800" // Ajouter la largeur explicite
    //       height="600" // Ajouter la hauteur explicite
    //       style={{
    //         marginLeft: "20%",
    //       }}
    //     />
    //   </div>
    // </section>
  );
};

export default ERPhierarchy;
