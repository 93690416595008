import ImportancySoftware from "components/modules/services/billing-software/telecom/ImportancySoftware";
import InvoiceManagement from "components/modules/services/billing-software/telecom/InvoiceManagement";
import Head from "components/modules/services/billing-software/telecom/Head";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import Extends from "components/modules/services/billing-software/telecom/Extends";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const BillingsTelecom = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <ImportancySoftware />
      <Extends />
      <InvoiceManagement />
      {/*<ClientTalk />*/}
      <Partners />
    </>
  );
};

export default BillingsTelecom;
