import ApiCreation from "components/modules/services/proposed-services/software-integration/ApiCreation";
import Avantages from "components/modules/services/proposed-services/software-integration/Avantages";
import BoostYourBusiness from "components/modules/services/proposed-services/software-integration/BoostYourBusiness";
import ClientTalk from "components/modules/showcase/ClientTalk";
import IntegerSolution from "components/modules/services/proposed-services/software-integration/IntegerSolution";
import MethodWorking from "components/modules/services/proposed-services/software-integration/MethodWorking";
import Partners from "components/modules/showcase/Partners";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SoftwareIntegration = () => {
  return (
    <>
      <BoostYourBusiness />
      <Breadcrumbs />
      <IntegerSolution />
      <ApiCreation />
      <Avantages />
      <MethodWorking />
      {/*<ClientTalk />*/}
      <Partners />
    </>
  );
};

export default SoftwareIntegration;
