import Header from "components/modules/services/proposed-services/solution-iper-cloud/Header";
import ProjectAppWeb from "components/modules/services/proposed-services/solution-iper-cloud/ProjectAppWeb";
import Technologies from "components/modules/services/proposed-services/solution-iper-cloud/Technologies";
import LastNews from "components/modules/showcase/LastNews";
import React from "react";
import Presentation from "components/modules/services/proposed-services/solution-iper-cloud/Presentation";
import HowWeWork from "components/modules/services/proposed-services/solution-iper-cloud/HowWeWork";
import Telechargement from "components/modules/services/proposed-services/solution-iper-cloud/Telechargement";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SolutionIperCloud = () => {
  return (
    <>
      <Header />
      <Breadcrumbs />
      <Presentation />
      <Technologies />
      <Telechargement />
      <HowWeWork />
      <ProjectAppWeb />
      {/*<LastNews />*/}
    </>
  );
};

export default SolutionIperCloud;