import React from "react";

const InfoBouton = () => {
  return (
    <div className="my-3">
      <a href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous" className="btn btn-primary me-3">
        Demander une démo
      </a>
      {/*<a href="/contact" className="btn btn-primary me-3">*/}
      {/*  CONTACT*/}
      {/*</a>*/}
      {/*<a*/}
      {/*  href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"*/}
      {/*  className="btn btn-primary me-3"*/}
      {/*>*/}
      {/*  RDV*/}
      {/*</a>*/}
    </div>
  );
};

export default InfoBouton;