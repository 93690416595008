import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Head from "components/modules/subscription/Head";
import Modules from "components/modules/showcase/PrincingTabs/Modules";
import ClientTalkforUs from "components/modules/showcase/ClientTalkforUs";

const Subscriptions = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Modules />
      <ClientTalkforUs />
      {/*<LastNews />*/}
    </>
  );
};

export default Subscriptions;
