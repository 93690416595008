import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import AvantagesAndPossibilities
  from "components/modules/services/proposed-services/dev-app-web/AvantagesAndPossibilities";
import DevelopmentApproach from "components/modules/services/proposed-services/dev-app-web/DevelopmentApproach";
import DevelopmentProces from "components/modules/services/proposed-services/dev-app-web/DevelopmentProces";
import Header from "components/modules/services/proposed-services/dev-app-web/Header";
import ProjectAppWeb from "components/modules/services/proposed-services/dev-app-web/ProjectAppWeb";
import Technologies from "components/modules/services/proposed-services/dev-app-web/Technologies";
import LastNews from "components/modules/showcase/LastNews";
import React from "react";

const AppDevWeb = () => {
  return (
    <>
      <Header />
      <Breadcrumbs />
      <AvantagesAndPossibilities />
      <Technologies />
      <ProjectAppWeb />
      <DevelopmentApproach />
      <DevelopmentProces />
      {/*<LastNews />*/}
    </>
  );
};

export default AppDevWeb;
