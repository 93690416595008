import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import ProjectLits from "components/modules/ressources/portfolio/completed-projects/ProjectLits";
import React from "react";

const Projects = () => {
  return (
    <>
      <Breadcrumbs />
      <ProjectLits />
      {/*<ClientTalk />*/}
      <Partners />
    </>
  );
};

export default Projects;
