import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes as sitemap } from "../../sitemap";

interface BreadcrumbItemProps {
  path: string;
  name: string;
  isLast: boolean;
}

// Generalizing the findRouteName function to accept any item structure
const findRouteName = (path: string, routes: any[]): string | undefined => {
  for (const route of routes) {
    if (
      (route.path === path ||
        route.pages?.some((page: any) => page.path === path)) &&
      route.name
    ) {
      return route.name;
    }
    if (route.pages) {
      const found = route.pages.find((page: any) => page.path === path)?.name;
      if (found) return found;

      for (const page of route.pages) {
        if (page.pages) {
          const nestedFound = findRouteName(path, page.pages);
          if (nestedFound) return nestedFound;
        }
      }
    }
  }
  return undefined;
};

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  path,
  name,
  isLast
}) => (
  <li
    className={`breadcrumb-item ${isLast ? "active" : ""}`}
    aria-current={isLast ? "page" : undefined}
  >
    {isLast ? name : <Link to={path}>{name}</Link>}
  </li>
);

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const breadcrumbs = pathnames.map((value, index) => {
    const path = `/${pathnames.slice(0, index + 1).join('/')}`;
    // Directly using sitemap imported without assuming its type
    const name = findRouteName(path, sitemap) || 'Unknown';
    return { path, name };
  }).filter(bc => bc.name !== 'Unknown'); // Filter out breadcrumbs with name 'Unknown'

  return (
    <nav className="container py-3" aria-label="breadcrumb">
      <ol className="breadcrumb mb-0">
        {/*<BreadcrumbItem path="/" name="Accueil" isLast={pathnames.length === 0} />*/}
        {/*{breadcrumbs.map((bc, index) => (*/}
        {/*  <BreadcrumbItem*/}
        {/*    key={index}*/}
        {/*    path={bc.path}*/}
        {/*    name={bc.name}*/}
        {/*    isLast={index === breadcrumbs.length - 1}*/}
        {/*  />*/}
        {/*))}*/}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
