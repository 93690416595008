import { tabsData } from "data/pricingTab";
import React, { useState } from "react";
import Packages from "./Packages";


const ModulesContent = () => {
  const [activeTab, setActiveTab] = useState("#ipbx");
  const [billingCycle, setBillingCycle] = useState("mois");
  const [commitmentYears, setCommitmentYears] = useState(1);
  const [optionsValues, setOptionsValues] = useState<{ [key: string]: number | string[] }>({});
  const [dropdownVisibility, setDropdownVisibility] = useState<{ [key: string]: boolean }>({});


  const handleBillingCycleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBillingCycle(event.target.checked ? "an" : "mois");
  };
  const handleCommitmentChange = (increment: number) => {
    setCommitmentYears(prevYears => {
      let newYears = prevYears + increment;
      if (newYears < 1) newYears = 1;
      if (newYears > 5) newYears = 5;
      return newYears;
    });
  };

  const toggleDropdown = (optionKey: string) => {


    setDropdownVisibility(prevState => ({
      ...prevState,
      [optionKey]: !prevState[optionKey]
    }));
  };
  return (
    <>
      <h2 className="text-center pb-3 pb-md-4">Nos modules</h2>
      {/* Nav tabs */}
      <ul
        className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4"
        role="tablist"
      >
        {tabsData.map(tab => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link text-nowrap ${activeTab === tab.id ? "active" : ""}`}
              id={`${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={tab.id}
              type="button"
              role="tab"
              aria-controls={tab.id.substring(1)}
              aria-selected={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      {/* Billing Cycle Switch */}
      {/*<div className="form-check form-switch d-flex justify-content-center mt-2 mb-4">*/}
      {/*  /!*<label className="form-check-label me-3 fs-6" htmlFor="billingCycle">*!/*/}
      {/*  /!*  Facturation mensuelle*!/*/}
      {/*  /!*</label>*!/*/}
      {/*  /!*<input*!/*/}
      {/*  /!*  type="checkbox"*!/*/}
      {/*  /!*  className="form-check-input mx-1"*!/*/}
      {/*  /!*  id="billingCycle"*!/*/}
      {/*  /!*  onChange={handleBillingCycleChange}*!/*/}
        {/*/>*/}
      {/*  /!*<label className="form-check-label fs-6 mx-3 me-5" htmlFor="billingCycle">*!/*/}
      {/*  /!*  Facturation annuelle*!/*/}
      {/*  /!*</label>*!/*/}
      {/*</div>*/}
      {/* Commitment Years Input */}
      <div className="d-flex justify-content-center align-items-center my-3">
        <span className="mx-2">{commitmentYears} année(s) d'engagement</span>
        <button
          onClick={() => handleCommitmentChange(-1)}
          className="btn btn-icon btn-secondary  border-white btn-sm rounded-circle "
        >
          <i className="bx bx-minus"></i>
        </button>
        <button
          onClick={() => handleCommitmentChange(1)}
          className="ms-1 btn btn-icon btn-secondary  border-white btn-sm rounded-circle "
        >
          <i className="bx bx-plus"></i>
        </button>
      </div>
      <Packages
        activeTab={activeTab}

        toggleDropdown={toggleDropdown}
        billingCycle={billingCycle}
        optionsValues={optionsValues}
        dropdownVisibility={dropdownVisibility}
        commitmentYears={commitmentYears}
      />
    </>
  )
}

export default ModulesContent;