import LastNews from "components/modules/showcase/LastNews";
import BenefitsTechSupport from "components/modules/services/proposed-services/maint-tech-supp/BenefitsTechSupport";
import Head from "components/modules/services/proposed-services/maint-tech-supp/Head";
import MaintenanceService from "components/modules/services/proposed-services/maint-tech-supp/MaintenanceService";
import SupportProcess from "components/modules/services/proposed-services/maint-tech-supp/SupportProcess";
import TechnicalSupport from "components/modules/services/proposed-services/maint-tech-supp/TechnicalSupport";
import React from "react";
import Partners from "components/modules/showcase/Partners";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const MaintTechSupp = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <MaintenanceService />
      <TechnicalSupport />
      <BenefitsTechSupport />
      <SupportProcess />
      {/*<LastNews />*/}
      <Partners />
    </>
  );
};

export default MaintTechSupp;
