import Evolution from "components/modules/a-propos/devenir-revendeur/Evolution";
import Head from "components/modules/a-propos/devenir-revendeur/Head";
import Opportunity from "components/modules/a-propos/devenir-revendeur/Opportunity";
import LastNews from "components/modules/showcase/LastNews";
import Process from "components/modules/a-propos/devenir-revendeur/Process";
import Avantages from "components/modules/a-propos/devenir-revendeur/Avantages";
import Testimonials from "components/modules/a-propos/devenir-revendeur/Testimonials";
import BecomeReseller from "components/modules/a-propos/devenir-revendeur/BecomeReseller";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const BecomeAReseller = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <Opportunity />
      <Avantages />
      <BecomeReseller />
      <Evolution />
      <Process />
      <Testimonials />
      {/*<LastNews />*/}
    </>
  );
};

export default BecomeAReseller;
