import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import logo_ipercom from "../assets/img/ipercom/services/ERP/Group.svg";
import bvannu from "../assets/img/ipercom/services/ERP/logo-bvannu.webp";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    siretNumber: "",
    companyName: "",
    idProductService: "",
    additionalDetails: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    // Convert idProductService to an integer
    const productServiceId = parseInt(formData.idProductService, 10);

    // Prepare request payload
    const requestData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      siretNumber: formData.siretNumber,
      companyName: formData.companyName,
      idProductService: productServiceId,
      additionalDetails: formData.additionalDetails
    };

    try {
      await axios.post("https://rct-backend.ipercom.io/api/CSoonRetailer", requestData, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/plain"
        }
      });
      setIsSubmitted(true);
    } catch (error) {
      setError("Erreur lors de l'envoi du formulaire. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const testimonials = [
    {
      text: "Je suis impressionnée par la qualité et la polyvalence d'ipercom. Leur large gamme de fonctionnalités répond à tous mes besoins professionnels. Je les recommande vivement à toute personne cherchant à optimiser son travail.",
      name: "Service développement",
      avatar: logo_ipercom
    },
    {
      text: "Depuis le temps que je cherchais à mutualiser mes services ERP et téléphonie. C'est maintenant chose faite. Mon entreprise a enfin trouvé son autonomie grâce à ipercom. De très bons conseils, pas de bla bla, de l'efficacité. Du travail de pro, réalisé par de vrais spécialistes. Mon entreprise utilise maintenant des services modernes, cela nous permet d'optimiser nos taches, dans un confort absolu. je conseille vivement.",
      name: "bvannu",
      avatar: bvannu
    }
  ];

  return (
    <>
      {/* Liens + Formulaire de contact */}
      <section className="position-relative bg-secondary">
        <div className="container position-relative zindex-2">
          <Breadcrumbs />
          <div className="row">
            {/* Liens de contact */}
            <div className="col-xl-6 col-lg-5 pb-4 pb-sm-5 mb-2 mb-sm-0">
              <div className="pe-lg-4 pe-xl-0">
                <h1 className="pb- pb-md-4 mb-lg-5">Contact</h1>
                <div className="d-flex align-items-start pb- mb-sm-1 mb-md-3">
                  <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                    <i className="bx bx-envelope" />
                  </div>
                  <div className="ps-3 ps-sm-4">
                    <h2 className="h4 pb-1 mb-2">E-mail </h2>
                    <p className="mb-2">
                      N'hésitez pas à nous écrire. Nous vous répondrons dans les
                      plus brefs délais.
                    </p>
                    <div className="btn btn-link btn-lg px-0">
                      Laisser un message
                      <i className="bx bx-right-arrow-alt lead ms-2" />
                    </div>
                  </div>


                </div>
              </div>
              <div className="pe-lg-4 pe-xl-0">

                <div className="card border-0 shadow-sm p-4 p-xxl-5">
                  <div className="d-flex justify-content-between pb-4 mb-2">
                <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                  <i className="bx bxs-quote-left"></i>
                </span>
                    <div className="d-flex">
                      <button
                        className="btn btn-prev btn-icon btn-sm me-2"
                        aria-label="Previous"
                      >
                        <i className="bx bx-chevron-left"></i>
                      </button>
                      <button
                        className="btn btn-next btn-icon btn-sm ms-2"
                        aria-label="Next"
                      >
                        <i className="bx bx-chevron-right"></i>
                      </button>
                    </div>
                  </div>

                  <Swiper
                    spaceBetween={24}
                    loop={true}
                    pagination={{
                      clickable: true
                    }}
                    navigation={{
                      prevEl: ".btn-prev",
                      nextEl: ".btn-next"
                    }}
                    modules={[Pagination, Navigation]}
                    className="swiper mx-0 mb-md-n2 mb-xxl-n3"
                  >

                    {testimonials.map((testimonial, index) => (
                      <SwiperSlide key={index} className="h-auto">
                        <figure className="card h-100 position-relative border-0 bg-transparent">
                          <p className="fw-semibold lh-base mb-0">
                            Avis Google :
                          </p>

                          <blockquote className="card-body p-0 mb-0">
                            <p className="fs-lg mb-0">{testimonial.text}</p>
                          </blockquote>
                          <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                            {/*<img*/}
                            {/*  src={testimonial.avatar}*/}
                            {/*  width="60"*/}
                            {/*  className="rounded-circle"*/}
                            {/*  alt={testimonial.name}*/}
                            {/*/>*/}
                            <div className="ps-3">
                              <p className="fw-semibold lh-base mb-0">
                                {testimonial.name}
                              </p>

                            </div>
                          </figcaption>
                        </figure>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination position-relative mt-5"></div>
                  </Swiper>
                </div>

              </div>
            </div>
            {/* Formulaire de contact */}
            <div className="col-xl-6 col-lg-7">
              <div className="card border-light shadow-lg  mb-5 px-2">
                <div className="card-body">
                  <h2 className="card-title pb-3">Formulaire de contact</h2>
                  {error && <div className="alert alert-danger">{error}</div>}
                  {!isSubmitted ? (
                    <Form onSubmit={handleSubmit}>
                      {/*<Form.Group className="mb-3">*/}
                      {/*  <Form.Label htmlFor="fn-revendeur">Prénom</Form.Label>*/}
                      {/*  <Form.Control*/}
                      {/*    type="text"*/}
                      {/*    id="fn-revendeur"*/}
                      {/*    required*/}
                      {/*    value={formData.firstName}*/}
                      {/*    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}*/}
                      {/*  />*/}
                      {/*  <Form.Control.Feedback type="invalid">Veuillez saisir votre prénom !</Form.Control.Feedback>*/}
                      {/*</Form.Group>*/}
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="ln-revendeur">Votre Nom</Form.Label>
                        <Form.Control
                          type="text"
                          id="ln-revendeur"
                          required
                          value={formData.lastName}
                          placeholder={"John Wick"}
                          onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        />
                        <Form.Control.Feedback type="invalid">Veuillez saisir votre nom de famille
                          !</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="email-revendeur">Votre Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="email-revendeur"
                          required
                          value={formData.email}
                          placeholder={"johnwick@ipercom.com"}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                        <Form.Control.Feedback type="invalid">Veuillez fournir une adresse e-mail valide
                          !</Form.Control.Feedback>
                      </Form.Group>
                      {/*<Form.Group className="mb-3">*/}
                      {/*  <Form.Label htmlFor="siret-number-revendeur">Numéro de siret</Form.Label>*/}
                      {/*  <Form.Control*/}
                      {/*    type="text"*/}
                      {/*    id="siret-number-revendeur"*/}
                      {/*    required*/}
                      {/*    value={formData.siretNumber}*/}
                      {/*    onChange={(e) => setFormData({ ...formData, siretNumber: e.target.value })}*/}
                      {/*  />*/}
                      {/*  <Form.Control.Feedback type="invalid">Veuillez entrer votre numéro de siret*/}
                      {/*    !</Form.Control.Feedback>*/}
                      {/*</Form.Group>*/}
                      {/*<Form.Group className="mb-3">*/}
                      {/*  <Form.Label htmlFor="company-name-revendeur">Nom de société et fonction</Form.Label>*/}
                      {/*  <Form.Control*/}
                      {/*    type="text"*/}
                      {/*    id="company-name-revendeur"*/}
                      {/*    required*/}
                      {/*    value={formData.companyName}*/}
                      {/*    onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}*/}
                      {/*  />*/}
                      {/*  <Form.Control.Feedback type="invalid">Veuillez entrer le nom de votre société !</Form.Control.Feedback>*/}
                      {/*</Form.Group>*/}
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="need-revendeur">Produit/Service</Form.Label>
                        <Form.Select
                          id="need-revendeur"
                          required
                          value={formData.idProductService}
                          onChange={(e) => setFormData({ ...formData, idProductService: e.target.value })}
                        >
                          <option value="">Choisissez un produit/service</option>
                          <option value="1">Solution de téléphone pour entreprise iper-X</option>
                          <option value="2">ERP ipercom (CRM, Facturation, Devis)</option>
                          <option value="3">Logiciel de caisse ipos</option>
                          <option value="4">Stockage collaboratif iper-cloud</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">Veuillez choisir un produit/service dans la liste !</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="comment-revendeur">Votre message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          id="comment-revendeur"
                          value={formData.additionalDetails}
                          placeholder={"Dites nous comment nous pourrions vous aider "}
                          onChange={(e) => setFormData({ ...formData, additionalDetails: e.target.value })}
                        />
                      </Form.Group>
                      <Button type="submit" variant="primary" size="lg" className="w-100" disabled={isSubmitting}>
                        {isSubmitting ? "En cours..." : "Soumettre"}
                      </Button>
                    </Form>
                  ) : (
                    <div className="text-center">
                      <h4>Merci pour votre intérêt!</h4>
                      <p>Votre demande a été envoyée avec succès. Nous vous contacterons sous peu.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Succursales */}
      <section className="container py-2 py-lg-4 py-xl-5 mb-2 mb-md-3">
        <div className="row py-5">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="d-flex flex-column h-100 shadow-sm rounded-3 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.3594260692607!2d-0.4146552234184406!3d44.875333972535316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5530f62c4ca3d5%3A0x589b3fdecca9a3fb!2s1%20Rue%20Jean%20Walton%2C%2033450%20Montussan!5e0!3m2!1sfr!2sfr!4v1719582423720!5m2!1sfr!2sfr"
                width={600}
                height={450}
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h2 className="h4 mb-4">Coordonnées</h2>
            <ul className="list-unstyled">
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-map text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                1 Rue Jean Walton, 33450, Montussan
              </li>
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-phone-call text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                05 18 22 22 00
              </li>
              <li className="d-flex">
                <i
                  className="bx bx-time-five text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                <div>
                  <strong className="text-nav">Lun - Ven :</strong> 8:00 - 18:00
                </div>
              </li>
            </ul>
            <div className="d-flex">
              <a
                href="https://www.facebook.com/profile.php?id=61562798741022"
                className="btn btn-icon btn-secondary btn-facebook me-3"
                aria-label="Facebook"
              >
                <i className="bx bxl-facebook" />
              </a>
              {/* <a
                href="#"
                className="btn btn-icon btn-secondary btn-instagram me-3"
                aria-label="Instagram"
              >
                <i className="bx bxl-instagram" />
              </a> */}
              <a
                href="https://x.com/ipercom_com"
                className="btn btn-icon btn-secondary btn-twitter me-3"
                aria-label="Twitter"
              >
                <i className="bx bxl-twitter" />
              </a>
              <a
                href="https://youtube.com/@ipercom?si=i3eUocJ72rtCmUfX"
                className="btn btn-icon btn-secondary btn-youtube me-3"
                aria-label="YouTube"
              >
                <i className="bx bxl-youtube" />
              </a>
              <a
                href="https://fr.linkedin.com/company/ipercom-com"
                className="btn btn-icon btn-secondary btn-linkedin"
                aria-label="Linkedin"
              >
                <i className="bx bxl-linkedin" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
